<template>
	<div class="card p-grid p-fluid">
		空白页面
	</div>
</template>

<script>
	export default {
		name: 'transPage',

		data() {
			return {}
		},
		created() {},
		beforeRouteEnter(to, from, next) {
			if (from.name !== '') { // 处理页面缓存后，返回首页再次进入缓存页时数据为更新
				// 该生命周期无法获取到this，因此把vm实例当作参数传递
				next(vm => {
					vm.$appState.processing=false;
					var target_path=vm.$route.params.target_path;
					vm.$router.push({
						path: target_path,
					})
				})
			}
		},
		mounted() {
		},
		methods: {

		},
	}
</script>

<style scoped lang="scss">
</style>
